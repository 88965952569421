import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import {
  FooterWrapper,
  FooterTopContent,
  TradeGoticText,
  SocialLinksBlock,
  FooterBottomContent,
  FooterLink,
  HomeIcon,
  FooterPdfLink,
  FooterLinksWrapper,
} from "./index.styled";
import FacebookIcon from "../../icons/facebook-icon";
import InstagramIcon from "../../icons/instagram-icon";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      dataJson {
        footerContent {
          footerTopBlock {
            phoneNumber
            email
            description
            facebookLink
            instagramLink
          }
          footerCopy
          footerMobileCopy
          footerLinks {
            footerLinkText
            footerLinkURL
          }
        }
      }
    }
  `);

  const copy = data.dataJson.footerContent.footerCopy;
  const footerMobileCopy = data.dataJson.footerContent.footerMobileCopy;
  const footerLinks = data.dataJson.footerContent.footerLinks;
  const footerTopBlock = data.dataJson.footerContent.footerTopBlock;
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <FooterWrapper>
      <FooterTopContent>
        <TradeGoticText>
          <a href={`tel:${footerTopBlock.phoneNumber}`}>
            {footerTopBlock.phoneNumber}
          </a>
        </TradeGoticText>
        <TradeGoticText>
          <a
            href={`mailto:${footerTopBlock.email}`}
            target={"_blank"}
            rel="noreferrer"
          >
            {footerTopBlock.email}
          </a>
        </TradeGoticText>
        <TradeGoticText>{footerTopBlock.description}</TradeGoticText>
        <SocialLinksBlock>
          <a
            href={footerTopBlock.facebookLink}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href={footerTopBlock.instagramLink}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </SocialLinksBlock>
      </FooterTopContent>
      <FooterBottomContent>
        <span>
          <span className="only-mobile-inline">©{currentYear} {footerMobileCopy}</span>
          <span className="only-desktop">©{currentYear} {copy}</span> <HomeIcon />
        </span>
        <br />
        <br className="only-mobile-inline" />
        <FooterLinksWrapper>
          {footerLinks.map((footerLink, i) => {
            if (footerLink.footerLinkText === "YOUR PRIVACY CHOICES") {
              return (
                <FooterPdfLink
                  aria-label={footerLink.footerLinkText}
                  key={i}
                  href="/Privacy-Rights-Request-Form.pdf"
                  rel="noopener"
                  target="_blank"
                >
                  {footerLink.footerLinkText}
                </FooterPdfLink>
              );
            } else {
              return (
                <FooterLink
                  aria-label={footerLink.footerLinkText}
                  key={i}
                  to={footerLink.footerLinkURL}
                >
                  {footerLink.footerLinkText}
                </FooterLink>
              );
            }
          })}
        </FooterLinksWrapper>
      </FooterBottomContent>
    </FooterWrapper>
  );
};

export default Footer;
