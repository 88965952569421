import { Link } from "gatsby";
import styled from "styled-components";
import EOHLogo from "icons/eoh-logo.svg";
import { mediaMax } from "../../helpers/media-queries";

export const FooterWrapper = styled.footer`
  padding: 100px 140px;
  width: 100%;
  ${mediaMax("tabletLandscape")} {
    padding: 60px 58px;
  }
`;

export const FooterTopContent = styled.div`
  margin-bottom: 52px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: pre-line;

  ${mediaMax("tabletLandscape")} {
    margin-bottom: 44px;
  }
`

export const TradeGoticText = styled.p`
  font-family: 'Trade-Gothic';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  max-width: 365px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #231F20;
  margin: 8px auto;

  & a {
    font-family: 'Trade-Gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    max-width: 365px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #231F20;
    margin: 8px auto;
    cursor: pointer;

    ${mediaMax("tabletLandscape")} {
      margin: 4px auto;
      font-size: 10px;
      line-height: 12px;
    }
  }

  ${mediaMax("tabletLandscape")} {
    margin: 4px auto;
    font-size: 10px;
    line-height: 12px;
  }
`;


export const SocialLinksBlock = styled.div`
  margin: 8px auto;
  gap: 16px;
  display: flex;

  & a {
    cursor: pointer;
  }

  ${mediaMax("tabletLandscape")} {
    margin: 4px auto;
  }
`

export const FooterBottomContent = styled.div`
font-family: "NHaas-Grotesk", san-serif;
font-weight: 400;
font-size: 14px;
line-height: 19px;
margin: 0 auto;
text-align: center;
letter-spacing: 0.03em;
color: #000000;
white-space: pre-line;
  ${mediaMax("tabletLandscape")} {
  font-style: normal;
  font-size: 10px;
  line-height: 11px;
}
`;

export const HomeIcon = styled.span`
display: inline-block;
  :after {
  content: "";
  display: block;
  width: 16px;
  height: 14px;
  background: url(${EOHLogo}) no-repeat;
}
`;

export const FooterLinksWrapper = styled.div`
  ${mediaMax("tabletLandscape")} {
  display: flex;
  flex-direction: column;
}
`;

export const FooterLink = styled(Link)`
align-items: center;
text-transform: uppercase;
text-decoration: underline;
padding: 4px;
`;

export const FooterPdfLink = styled.a`
align-items: center;
text-transform: uppercase;
text-decoration: underline;
padding: 4px;
`;
