import React from "react";

export const HeaderContext =  React.createContext({});

export const HeaderProvider = ({ children }) => {
  const [isLogoLight, setIsLogoLight] = React.useState(false);
  const [isMenuLight, setIsMenuLight] = React.useState(false);

  return (
    <HeaderContext.Provider
      value={{
        isLogoLight,
        isMenuLight,
        setIsLogoLight,
        setIsMenuLight,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
export const useHeaderColor = () =>  React.useContext(HeaderContext);
