import styled from "styled-components";
import { mediaMax } from "helpers/media-queries";

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const PageWrapper = styled.div`
  position: relative;
  ${mediaMax("tabletLandscape")} {
    padding-top: 100px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .page {
    top: 0;
    transition: opacity 0.25s;
    height: 100%;
    width: 100%;
  }
  .page.entering,
  .page.entered {
    position: relative;
    opacity: 1;
  }
  .page.exiting,
  .page.exited {
    position: absolute;
    opacity: 0;
  }
`;

export const HideBlock = styled.div`
  display: ${({ hide }) => hide && "none"};
`;
